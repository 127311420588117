/**
 * @description Dynamic background component styles
 * @author Ahmed AL-Yasiri
 * @company TTP - TopTier-performance
 * @copyright Proprietary
 */
 .dynamic-bg {
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    .db {
      &-one,
      &-two,
      &-three,
      &-four,
      &-five {
        display: none;
      }
  
      &-one {
        background: transparent;
        transform: matrix(0.4, -0.92, -0.92, -0.4, 0, 0);
        animation: oneAnimation 20s ease-in-out infinite;
        box-shadow: 0px 0px 500px 150px rgba(#ff9ae3, 0.4);
      }
  
      &-two {
        transform: matrix(0.4, -0.92, -0.92, -0.4, 0, 0);
        animation: twoAnimation 15s ease-in-out infinite;
        box-shadow: 0px 0px 500px 150px rgba(#ffa451, 0.4);
      }
  
      &-three {
        transform: matrix(0.92, -0.96, -0.25, -0.8, 0, 0);
        box-shadow: 0px 0px 500px 150px rgba(255, 39, 143, 0.3);
      }
  
      &-four {
        top: -10%;
        right: 10%;
        left: auto;
        transform: matrix(1, 0, 0, -1, 0, 0);
        animation: oneAnimation 15s ease-in-out infinite;
        box-shadow: 0px 0px 500px 150px rgba(175, 236, 255, 0.68);
      }
  
      &-five {
        bottom: -20%;
        top: auto;
        left: 20%;
        left: auto;
        transform: matrix(1, 0, 0, -1, 0, 0);
        animation: oneAnimation 15s ease-in-out infinite;
        box-shadow: 0px 0px 500px 150px rgba(175, 236, 255, 0.68);
      }
    }
  }
  
  @keyframes oneAnimation {
        0% {
            transform: translateX(-25rem);
        }
  
        50% {
            transform: translateX(25rem);
        }
  
        100% {
            transform: translateX(-25rem);
        }
  }
  
  @keyframes twoAnimation {

        0% {
            transform: translateY(-25rem);
        }
  
        50% {
            transform: translateY(25rem);
        }
  
        100% {
            transform: translateY(-25rem);
        }
  }







@media only screen and (min-width: 760px){
    .db-one, .db-two, .db-three, .db-four, .db-five {
        border-radius: 50%;
        position: absolute;
        display: block !important;
        width: 1px;
        height: 1px;
        top: 50%;
        left: 50%;
    }    
}
  
  