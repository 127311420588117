@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500&display=swap');


@import 'dynamic-background';

a{
    transition: all 0.2s ease-out;
}

a:hover{
    color: #5353fe;
}

html{
    scroll-behavior: smooth;
    scroll-padding-top: 10em; 
    overflow-x: hidden;
}

.font-alt{
    font-family: trajan-pro-3, serif;
}

.diamond-list{
    margin-left: 17px;
}

.diamond-list li:before {
    content: '';
    display: inline-block;
    height: 25px;
    width: 35px;
    background-color: rgba(255, 255, 255, 0.7);
    width: 3px;
    height: 3px;
    border-radius: 20px;
    margin-left: -35px;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.diamond-list li{
    margin-bottom: 10px;
    padding-left: 10px;
    position: relative;
}

iframe{
    display: none !important;
}

.min-h-full{
    min-height: 100vh;
}

.grid-column{
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: 40px;
}

@media only screen and (max-width: 760px){
    .grid-column{
        padding-left: 0;
        padding-right: 0;
    }
}

body{
    font-family: Poppins, sans-serif;
    font-weight: 200;
    background-color: #0a0a0a;
    color: #fff;
    letter-spacing: 0.2px;
    overflow-x: hidden;
}

.text-purple{
    color: #3C3C48;
}

.text-gradient{
    background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-background-clip: text;
    
      
    -webkit-text-fill-color: transparent;
}

.font-title{
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.8em;
    font-style: italic;
}

.col-1{
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.col-2{
    max-width: 800px;
    margin: 0 auto;
}


.grid-lines {
    margin: 0 auto;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    right: 0;
    bottom: 0;
    pointer-events: none;
    mix-blend-mode: screen;
  }

  .body-line {
      display: inline-block;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
  }





.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    margin: 0 auto;
    font-family: 'DM Sans', sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table tr th {
    background-image: linear-gradient(45deg, #08081a, #0f1919);
    color: #ffffff;
    text-align: left;
    font-size: 1.2em;
    font-style: italic;
    font-weight: normal;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    border: 1px solid #151515;
}

.button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 80px;
    background-color: #fff;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.button.big{
    padding: 15px 23px;
    font-size: 1.2em;
    font-family: Montserrat;
}

.button:hover{
    background-color: #b3a2e1;
}

.button.alert{
    background: #e7b045;
}

.button.dark{
    background-color: #444;
    color: #fff;
}

.button.dark:hover{
    background-color: #666;
}

.mid-screen-msg{
    text-align: center;
    padding-top: 200px;
}

.pill{
    background-color: #444;
    font-size: 0.6em;
    text-transform: uppercase;
    padding: 4px;
    border-radius: 5px;
    letter-spacing: 1px;
    opacity: 0.3;
    vertical-align: middle;
}

.pill.active{
    opacity: 1;
}

.hover-opacity{
    opacity: 1;
    transition: all .3s ease;
}

.hover-opacity:hover{
    opacity: 0.5;
}


.parallax-container{
    position: absolute;
    top: 40%;
    left: 180px;
    transform: translateY(-50%);
}

.mint-button{
    background-color: #ff560c;
    color: #fff;
    padding: 10px 30px;
    border-radius: 10px;
}

@media only screen and (max-width: 760px){
    .parallax-container{
        left: 50%;
        transform: translateX(-60%);
    }
    .grid-column{
        border: 0;
    }
    .artwork-column{
        padding-bottom: 400px;
    }
    .artwork{
        opacity: 1 !important;
    }
}


